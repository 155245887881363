import { useContext, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import AuthContext from "../context/AuthContext";
import apiEndpoints from "../utils/ApiEndpoints";

const baseURL = process.env.API_BASE_URL;

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);

  // custom axios instance with JWT
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });

  // interceptors for all HTTP calls. checks jwt expiration date,
  // if it is expired, refresh the tokens and send the request
  axiosInstance.interceptors.request.use(async (req) => {
    if (authTokens) {
      const user = jwt_decode(authTokens.accessToken);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      if (!isExpired) return req; // if jwt is not expired, send the request without refreshing tokens
    }

    const response = await axios.post(
      apiEndpoints.refresh_token,
      authTokens
    ); // refresh the tokens

    setAuthTokens(response.data.tokenPair); // update the auth state
    setUser(jwt_decode(response.data.tokenPair.accessToken)); // update the auth state

    return req;
  });

  return axiosInstance;
};

export default useAxios;
