import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();
  const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "80vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(0, 0),
  }));
  return (
    <Box flex={18} p={2}>
      <Container>
        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant="h3" paragraph sx={{ paddingBottom: 2 }}>
            Sorry, this page is not available to you.
          </Typography>

          <Button
            onClick={() => navigate(-1)}
            size="medium"
            variant="contained"
          >
            Go back
          </Button>
        </ContentStyle>
      </Container>
    </Box>
  );
};

export default Unauthorized;
