import React, { useState, useContext } from "react";
import { List, Divider, ListSubheader, Typography } from "@mui/material";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PublicIcon from "@mui/icons-material/Public";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { Logout } from "@mui/icons-material";
import { Link } from "react-router-dom";
import StyledListItemButton from "../common/buttons/StyledListItemButton";
import AuthContext from "../../context/AuthContext";


const SideBarMenu = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { signOutUser, user } = useContext(AuthContext);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  /* list menu */
  return (
    <>
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              marginBottom: "15px",
              marginTop: "15px",
              backgroundColor: "#3e8acc",
              padding: "8px"
            }}>
            <LocalHospitalIcon style={{ color: "white" }} />
            <Typography variant="h5" style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              marginLeft: "18px",
              color: "white",
            }}>
              Emergency
            </Typography>
          </ListSubheader>
        }
      >

        <StyledListItemButton
          component={Link}
          to="/"
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
          itemText="Provider Performance"
          itemIcon={<AnalyticsIcon color="primary" />}
        />

        {(user.role == "LLIFAdmin") && (
          <StyledListItemButton
            component={Link}
            to="/dataimports"
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
            itemText="Data Imports"
            itemIcon={<ImportExportIcon color="primary" />}
          />
        )}

        <StyledListItemButton
          component={Link}
          to="/departmentperformance"
          selected={selectedIndex === 2}
          isDisabled={true}
          onClick={(event) => handleListItemClick(event, 2)}
          itemText="Department Performance"
          itemIcon={<ApartmentIcon color="primary" />}
        />

        <StyledListItemButton
          component={Link}
          to="/orgperformance"
          selected={selectedIndex === 3}
          isDisabled={true}
          onClick={(event) => handleListItemClick(event, 3)}
          itemText="Organization Performance"
          itemIcon={<PublicIcon color="primary" />}
        />

        <Divider
          style={{
            marginTop: "80px",
          }}
        />

        <StyledListItemButton
          itemText="Logout"
          onClick={signOutUser}
          itemIcon={<Logout color="primary" />}
        />
      </List>
    </>
  );
};

export default SideBarMenu;
