import useAxios from "../hooks/useAxios";
import apiEndpoints from "../utils/ApiEndpoints";
export const usePerformanceApi = () => {
  const api = useAxios();

  return {
    // endpoint used to get all stat metric results for provider table in ProviderPerformance page
    getProviderPerformance: (providerId, fromDate, siteId) =>
      api.get(
        apiEndpoints.providerPerformance.replace("{providerId}", providerId),
        {
          params: {
            fromDate,
            siteId,
          },
        }
      ),
  };
};
