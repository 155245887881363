import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import Layout from "../../components/common/Layout/Layout";

import {
  Box,
  Button,
  TextField,
  Grid,
  Container,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiEndpoints from "../../utils/ApiEndpoints";

/* 
  Sends security code to the provided email only if the email address user 
  entered is in the database. Security code is used for Forgot Password and
  Automatic Reset Password (30 day period) functionalities. 
*/
const SecurityCode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formik = useFormik({
    initialValues: {
      securityCode: "",
    },
    validationSchema: Yup.object({
      securityCode: Yup.string()
        .test("len", "Must be exactly 6 characters", (val) => val?.length === 6)
        .required("Required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      axios
        .post(apiEndpoints.verify_resetpassword, {
          email: searchParams.get("email"),
          code: values.securityCode,
        })
        .then((response) => {
          if (response.data.success === true) {
            setError(null);
            setOpen(false);
            navigate({
              pathname: "/resetpassword",
              search: createSearchParams({
                email: searchParams.get("email"),
                token: response.data.token,
              }).toString(),
            });
          } else if (response.data.success === false) {
            setError(response.data.errors[0]);
          }
          setIsLoading(false);
        });
    },
  });

  const [open, setOpen] = useState(true);

  useEffect(() => {
    //if the email not in url, go back to login
    if (searchParams.get("email") === null) {
      navigate("/login");
    }
  }, []);

  const resendCode = () => {
    axios
      .post(apiEndpoints.auth_sendSecurityCode, {
        email: searchParams.get("email"),
      })
      .then((response) => {
        if (response.data.success === true) {
          setError(null);
          formik.setFieldValue("securityCode", "");
        }
      });
  };

  return (
    <Layout>
      <Container component="main" maxWidth="xs">
        <Dialog open={open} maxWidth="xs">
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>Enter Security Code</DialogTitle>

            <DialogContent>
              <DialogContentText>
                If the email address you entered is in our database, a security
                code has just been sent to it. Once you receive the code, please
                enter it here:
              </DialogContentText>

              <TextField
                margin="normal"
                fullWidth
                id="securityCode"
                label="Security Code"
                name="securityCode"
                autoComplete="securityCode"
                autoFocus
                value={formik.values.securityCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.securityCode && formik.errors.securityCode
                    ? formik.errors.securityCode
                    : null
                }
              />
            </DialogContent>
            {error && (
              <Grid container justifyContent="flex-end">
                <Grid item xs>
                  <Box display="flex" justifyContent="center">
                    <Alert variant="outlined" severity="error">
                      {error}
                    </Alert>
                  </Box>
                </Grid>
              </Grid>
            )}

            <DialogActions>
              <Button onClick={() => navigate("/login")}>Cancel</Button>
              {error === "Invalid security code." || error === null ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  {...(isLoading &&
                    !error && {
                      endIcon: <CircularProgress size={25} />,
                      disabled: true,
                    })}
                >
                  Verify Code
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resendCode()}
                  {...(isLoading &&
                    !error && {
                      endIcon: <CircularProgress size={25} />,
                      disabled: true,
                    })}
                >
                  Resend Code
                </Button>
              )}
            </DialogActions>
          </form>
        </Dialog>
      </Container>
    </Layout>
  );
};

export default SecurityCode;
