import React, { useState } from "react";
import axios from "axios";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Grid,
  Container,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Layout from "../../components/common/Layout/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiEndpoints from "../../utils/ApiEndpoints";

/* 
 * Handles forgot password functionality. A security code is sent to 
 * the email user entered associated with his/her account
 */
const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email format").required("Required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      axios
        .post(apiEndpoints.forgot_password, values)
        .then((response) => {
          if (response.data.success === true) {
            setError(null);
            navigate({
              pathname: "/securitycode",
              search: createSearchParams({
                email: values.email,
              }).toString(),
            });
          } else if (response.data.success === false) {
            setError(response.data.errors[0]);
          }
          setIsLoading(false);
        });
    },
  });

  const [open, setOpen] = useState(true);

  return (
    <Layout>
      <Container component="main" maxWidth="xs">
        <Dialog open={open} maxWidth="xs">
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>Forgot your password?</DialogTitle>

            <DialogContent>
              <DialogContentText>
                Please enter the email associated with your account to get a
                password reset code.
              </DialogContentText>

              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null
                }
              />
            </DialogContent>
            {error && (
              <Grid container justifyContent="flex-end">
                <Grid item xs>
                  <Box display="flex" justifyContent="center">
                    <Alert variant="outlined" severity="error">
                      {error}
                    </Alert>
                  </Box>
                </Grid>
              </Grid>
            )}

            <DialogActions>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                {...(isLoading &&
                  !error && {
                    endIcon: <CircularProgress size={25} />,
                    disabled: true,
                  })}
              >
                Send Request
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Container>
    </Layout>
  );
};

export default ForgotPassword;
