import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../components/common/Layout/Layout";
import { SHA512 } from "crypto-js";

import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Alert,
  CircularProgress,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiEndpoints from "../../utils/ApiEndpoints";

/*
 * Handles reset password for Forgot password and Automatic reset password functionalities.
 * User needs to follow requirements to be able to change password.
 */
const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Must be at least 8 characterrs")
        .max(30, "Must be less than 30 characters")
        .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
        .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
        .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
        .matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character')
        .required("Required"),
      passwordConfirmation: Yup.string()
        .min(8, "Must be at least 8 characterrs")
        .max(30, "Must be less than 30 characters")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      axios
        .post(apiEndpoints.auth_resetPassword, {
          email: searchParams.get("email"),
          token: searchParams.get("token"),
          password: SHA512(values.password).toString(),
        })
        .then((response) => {
          if (response.data.success === true) {
            setError(null);
            setOpenModal(true);
          } else if (response.data.success === false) {
            setError(response.data.errors[0]);
          }
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    //if the username not in url, go back to login
    if (searchParams.get("email") === null) {
      navigate("/login");
    }
  }, []);

  const [open, setOpen] = useState(true);
  return (
    <Layout>
      <Container component="main" maxWidth="xs">
        <Dialog open={open} maxWidth="xs">
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>Reset Password</DialogTitle>

            <DialogContent>
              <TextField
                margin="normal"
                fullWidth
                id="password"
                label="Please enter your new password"
                name="password"
                type="password"
                autoComplete="password"
                autoFocus
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : null
                }
              />

              <TextField
                margin="normal"
                fullWidth
                name="passwordConfirmation"
                label="Please enter your new password again"
                type="password"
                id="passwordConfirmation"
                autoComplete="current-password"
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                    ? formik.errors.passwordConfirmation
                    : null
                }
              />
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Alert severity="info">
                  <Typography variant="subtitle2" gutterBottom>
                    Password must contain at least 8 characters, including a
                    number, a special character, a lowercase letter and a
                    uppercase letter.
                  </Typography>
                </Alert>
              </Grid>
            </DialogContent>
            {error && (
              <Grid container justifyContent="flex-end">
                <Grid item xs>
                  <Box display="flex" justifyContent="center">
                    <Alert variant="outlined" severity="error">
                      {error}
                    </Alert>
                  </Box>
                </Grid>
              </Grid>
            )}

            <DialogActions>
              <Button onClick={() => navigate("/login")}>Cancel</Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                {...(isLoading &&
                  !error && {
                    endIcon: <CircularProgress size={25} />,
                    disabled: true,
                  })}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Container>
      <Modal open={openModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h6">
            Password Change Successful
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your password has been succesfully changed.
          </Typography>
          <Button onClick={() => navigate("../login")}>Go back to login</Button>
        </Box>
      </Modal>
    </Layout>
  );
};

export default ResetPassword;
