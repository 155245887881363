import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useUserApi } from "../../api/User";

import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  AppBar as MuiAppBar,
  Menu,
  CircularProgress,
  Avatar,
} from "@mui/material";

import {
  Menu as MenuIcon,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import SideBarMenu from "./SideBarMenu";
import AuthContext from "../../context/AuthContext";

const drawerWidth = 280;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#eaeff1",
    minHeight: "calc(100vh)",
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function SideBarWithDrawer({ content }) {
  const theme = useTheme();
  const [open, setOpen] = useState(true); // drawer
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [isDrawerOpenOrClose, setIsDrawerOpenOrClose] = useState(false);
  const [userName, setUserName] = useState([]);
  const { user } = useContext(AuthContext);
  const userApi = useUserApi();

  const handleDrawerOpen = () => {
    setIsDrawerOpenOrClose(true);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpenOrClose(true);
    setOpen(false);
  };

  /* App Bar */
  const Icons = styled(Box)(({ theme }) => ({
    display: "none",
    gap: "20px",
    marginRight: "25px",
    marginTop: "10px",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  }));

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* app bar ends*/
  useEffect(() => {
    setTimeout(() => setIsDrawerOpenOrClose(false), 300); //to delay the content when drawer opened/closed
  }, [open]);

  useEffect(() => {
    if (user != null) {
      userApi.getUserName(user.nameid).then((res) => {
        const username = `${res.data.firstName} ${res.data.lastName}`;
        setUserName(username);
      });
    }
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{ backgroundColor: "#042c49" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ mr: 2, ...(!open && { display: "none" }) }}
          >
            LLIF
          </Typography>
        </Toolbar>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          open={openMenu}
          onClose={(e) => setOpenMenu(false)}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        ></Menu>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Toolbar sx={{ ...(!open && { display: "none" }) }}>
            <Icons>
              <Avatar sx={{ width: 90, height: 90, marginTop: 1 }}>
                <AccountCircleIcon sx={{ width: 100, height: 100 }} />
              </Avatar>
            </Icons>
          </Toolbar>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Typography variant="h6" mt={2} mb={1} sx={{ textAlign: "center" }}>
          {userName}
        </Typography>
        <Divider />
        <SideBarMenu />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {isDrawerOpenOrClose ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: theme.spacing(1),
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{ ...(!open && { marginX: 20 }), ...(open && { marginX: 5 }) }}
          >
            {content}
          </Box>
        )}
      </Main>
    </Box>
  );
}
