/*
 * Base URL for API calls. If we have a value set through process.env, we
 * prepend that. Otherwise we default to the same host and port.
 */
const API_BASE_URL = process.env.API_BASE_URL?.replace(/\/$/, '') || '/api';

const apiEndpoints = Object.entries({
    auth_login: '/auth/login',
    auth_resetPassword: '/users/resetpassword',
    auth_sendSecurityCode: '/users/sendresetpasswordcode',
    auth_verifySecurityCode: '/users/verifyresetpasswordcode',
    dataImport_importFile: '/DataImport/import/file/{fileName}',
    dataImport_listFiles: '/DataImport/files/list',
    providerPerformance: '/Performance/Provider/{providerId}',
    sites_byProvider: '/Sites/GetProviderSites/{providerId}',
    userName_byUserId: '/Users/user/{userId}',
    provider_list: '/Performance/Providers',
    refresh_token: '/auth/refreshtoken',
    forgot_password: '/users/sendresetpasswordcode',
    verify_resetpassword: '/users/verifyresetpasswordcode',
    update_password: '/users/sendresetpasswordcode',
    endUser_licenseAgreement: '/users/GetUserLicenseAgreement/{userLicenseId}',
    userInfo_byEmail: '/users/GetUserInfoByEmail/{userEmail}',
    EULA_acceptedDate: '/users/UpdateEULADateAccepted',
}).reduce(
    (newObj, [key, shortEndpoint]) => ({...newObj, [key]: `${API_BASE_URL}${shortEndpoint}`}), 
    {});

export default apiEndpoints;
