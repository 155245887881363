import React from "react";
import { Routes, Route } from "react-router-dom";
import PublicRoutes from "../utils/PublicRoutes";
import PrivateRoutes from "../utils/PrivateRoutes";
import ProviderPerformance from "../pages/Emergency/ProviderPerformance";
import Page404 from "../pages/Page404";
import OrganizationPerformance from "../pages/Emergency/OrganizationPerformance";
import DepartmentPerformance from "../pages/Emergency/DepartmentPerformance";
import DataImports from "../pages/Emergency/DataImports";
import Login from "../pages/Login";
import ForgotPassword from "../pages/users/ForgotPassword";
import SecurityCode from "../pages/users/SecurityCode";
import ResetPassword from "../pages/users/ResetPassword";
import AutomaticResetPassword from "../pages/users/AutomaticResetPassword";
import Unauthorized from "../pages/Unauthorized";
function RouteConfig() {
  return (
    <Routes>
      <Route element={<PublicRoutes />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="securitycode" element={<SecurityCode />} />
        <Route path="resetpassword" element={<ResetPassword />} />
        <Route path="autoresetpassword" element={<AutomaticResetPassword />} />
      </Route>

      {/* protected routes */}
      <Route element={<PrivateRoutes allowedRoles={["Admin"]} />}>
        <Route
          path="departmentperformance"
          element={<DepartmentPerformance />}
        />
        <Route path="orgperformance" element={<OrganizationPerformance />} />
      </Route>

      <Route element={<PrivateRoutes allowedRoles={["Physician"]} />}></Route>
      
      <Route element={<PrivateRoutes allowedRoles={["LLIFAdmin"]} />}>
        <Route path="dataimports" element={<DataImports />} />
      </Route>

      <Route
        element={
          <PrivateRoutes allowedRoles={["Admin", "Physician", "LLIFAdmin"]} />
        }
      >
        <Route path="/" element={<ProviderPerformance />} />
      </Route>

      {/* catch all routes */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
export default RouteConfig;
