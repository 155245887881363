import React from "react";
import { sidebarStyles } from "../../sideBar/styles";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

const StyledListItemButton = (props) => {
  const { itemText, itemIcon, collapseIcon, isDisabled, ...rest } = props;
  return (
    <ListItemButton {...rest} disabled= {isDisabled}>
      <ListItemIcon>{itemIcon}</ListItemIcon>
      <ListItemText sx={sidebarStyles.text} primary={itemText}/>
      {collapseIcon}
    </ListItemButton>
  );
};

export default StyledListItemButton;
