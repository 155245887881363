import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/* Figures to be used for the peer distribution graph */
const svgPlusSignUserAvg = (
  x,
  index,
  marginX = 8,
  marginY = 2,
  width = 200,
  height = 20,
  color = "blue",
  strokeWidth = 4
) => {
  const xScale = width - 2 * marginX;
  const xBase = xScale * x + marginX;

  const yBase = height / 2;
  const size = height / 2 - marginY;

  return [
    <line
      key={index}
      x1={xBase}
      x2={xBase}
      y1={yBase - size}
      y2={yBase + size}
      strokeWidth={strokeWidth}
      stroke={color}
    />,
    <line
      key={index + 1}
      x1={xBase - size}
      x2={xBase + size}
      y1={yBase}
      y2={yBase}
      strokeWidth={strokeWidth}
      stroke={color}
    />,
  ];
};

const svgDiamondPeerAvg = (
  x,
  index,
  marginX = 8,
  size = 10,
  width = 200,
  height = 20,
  color = "gray"
) => {
  const xBase = x * (width - 2 * marginX) + marginX;
  const yBase = height / 2;

  return (
    <rect
      key={index}
      x={xBase - size / 2}
      y={yBase - size / 2}
      width={size}
      height={size}
      fill={color}
      transform={`rotate(45, ${xBase}, ${yBase})`}
    />
  );
};

const svgCircle = (
  x,
  index,
  radius = 3,
  marginX = 8,
  width = 200,
  height = 20,
  color = "lightblue"
) => (
  <circle
    key={index}
    cx={marginX + x * (width - 2 * marginX)}
    cy={height / 2}
    r={radius}
    fill={color}
  />
);

const PeerDistribution = ({ peerDistribution, width = 200, height = 20 }) => {
  let radii = peerDistribution.map(({ point, isUser, isPeerAverage }) => {
    const numNear = peerDistribution.filter(
      (nearby) => Math.abs(nearby.point - point) < 0.05
    ).length;

    return Math.log(numNear * numNear);
  });

  return (
    <svg width={width} height={height}>
      {peerDistribution.map(({ point, isUser, isPeerAverage }, index) =>
        isUser
          ? svgPlusSignUserAvg(point, index)
          : isPeerAverage
          ? svgDiamondPeerAvg(point, index)
          : svgCircle(point, index, radii[index])
      )}
    </svg>
  );
};

// Table to be displayed in ProviderPerformance page
export default function ProviderTable({ data, tableHeaders }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) =>
              header == "" ? (
                <TableCell style={{ textAlign: "left" }} key={index}>
                  {header}
                </TableCell>
              ) : header == "Peer Distribution" ? (
                <TableCell style={{ textAlign: "center" }} key={index}>
                  {header}
                </TableCell>
              ) : (
                <TableCell style={{ textAlign: "right" }} key={index}>
                  {header}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell align="left" component="th" scope="row">
                {row.rowHeading}
              </StyledTableCell>
              <StyledTableCell align="right">{row.userAverage}</StyledTableCell>
              <StyledTableCell align="right">{row.peerAverage}</StyledTableCell>
              <StyledTableCell align="center">
                <PeerDistribution peerDistribution={row.peerDistribution} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
