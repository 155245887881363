import React from "react"
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
const Page404 = () => {

    const ContentStyle = styled("div")(({ theme }) => ({
        maxWidth: 480,
        margin: "auto",
        minHeight: "80vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: theme.spacing(0, 0)
    }));
    return (
        <Box flex={18} p={2} >
            <Container>
                <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
                    <Typography variant="h3" paragraph sx={{ paddingBottom: 2 }}>
                        Sorry, page not found!
                    </Typography>

                    <Typography sx={{ color: "text.secondary", paddingBottom: 2 }}>
                        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
                        sure to check your spelling.
                    </Typography>


                    <Button to="/" size="medium" variant="contained" component={RouterLink}>
                        Go to Home
                    </Button>
                </ContentStyle>
            </Container>
        </Box>
    )
}

export default Page404