import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    Button,
    Backdrop,
  } from "@mui/material";
  import { useTheme } from "@mui/material/styles";
  import { Check, Close } from "@mui/icons-material";
  
  import React from "react";
  
  const LicenseAgreementDialog = (props) => {
    const { licenseDialog, setLicenseDialog } = props;
    const theme = useTheme();
    return (
      <Dialog
        open={licenseDialog.isOpen}
        maxWidth="sm"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          position: "absolute",
          top: theme.spacing(5),
          paddingY: theme.spacing(2),
        }}
      >
        <DialogTitle sx={{ paddingY: theme.spacing(3) }}>
          <Typography component="span" variant="h6">
            {licenseDialog.generalTitle}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{ paddingY: theme.spacing(5), paddingRight: theme.spacing(3) }}
        >
          <Typography variant="body2"> {licenseDialog.summary}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection1}</Typography>
          <Typography variant="body2"> {licenseDialog.section1}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection2}</Typography>
          <Typography variant="body2"> {licenseDialog.section2}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection3}</Typography>
          <Typography variant="body2"> {licenseDialog.section3}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection4}</Typography>
          <Typography variant="body2"> {licenseDialog.section4}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection5}</Typography>
          <Typography variant="body2"> {licenseDialog.section5}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection6}</Typography>
          <Typography variant="body2"> {licenseDialog.section6}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection7}</Typography>
          <Typography variant="body2" sx={{marginTop: 1}}> {licenseDialog.section7a}</Typography>
          <Typography variant="body2" sx={{marginTop: 1}}> {licenseDialog.section7b}</Typography>
          <Typography variant="body2" sx={{marginTop: 1}}> {licenseDialog.section7c}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection8}</Typography>
          <Typography variant="body2"> {licenseDialog.section8}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection9}</Typography>
          <Typography variant="body2"> {licenseDialog.section9}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection10}</Typography>
          <Typography variant="body2"> {licenseDialog.section10}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection11}</Typography>
          <Typography variant="body2"> {licenseDialog.section11}</Typography>
          
          <Typography variant="subtitle2" sx={{marginTop: 2}}> {licenseDialog.titleSection12}</Typography>
          <Typography variant="body2"> {licenseDialog.section12}</Typography>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: theme.spacing(2) }}>
          <Button
            variant="contained"
            color="success"
            onClick={licenseDialog.onConfirm}
            startIcon={<Check />}
          >
            Accept
          </Button>
          <Button
            variant="outlined"
            onClick= {licenseDialog.onDecline}
            startIcon={<Close />}
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default LicenseAgreementDialog;
  