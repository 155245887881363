import GridLayout from '../../components/common/GridLayout/GridLayout';
import React, { useEffect, useState, useContext } from 'react';
import { Grid, Box, Paper, Typography, CircularProgress } from '@mui/material';
import ProviderTable from '../../components/common/table/ProviderTable';
import StyledBasicDropdown from '../../components/common/Dropdown/StyledBasicDropdown';
import AuthContext from '../../context/AuthContext';
import dayjs from 'dayjs';
import { usePerformanceApi } from '../../api/Performance';
import { useSitesApi } from '../../api/Sites';
import { useUserApi } from '../../api/User';
const ProviderPerformance = () => {
  const performanceApi = usePerformanceApi();
  const sitesApi = useSitesApi();
  const userApi = useUserApi();
  const [filterByDate, setFilterByDate] = useState(0);
  const [filterBySite, setFilterBySite] = useState(0);
  const { providerId, user } = useContext(AuthContext);

  const [statsData, setStatsData] = useState([]);
  const [providerSites, setProviderSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState([]);
  const [filterByProvider, setFilterByProvider] = useState(providerId);
  const [providerList, setProviderList] = useState([]);
  const [userNameTableHeader, setUserNameTableHeader] = useState('');

  const dateRanges = [
    { value: 0, display: 'All time' },
    { value: 1, display: 'Past month' },
    { value: 3, display: 'Past 3 months' },
    { value: 12, display: 'Past year' },
  ];

  //Handles provider performance stats in provider table for different user roles
  useEffect(() => {
    if (filterByProvider != '' || providerId != null) {
      if (user.role == 'Physician') {
        performanceApi
          .getProviderPerformance(
            providerId,
            filterByDate !== 0
              ? dayjs()
                  .subtract(filterByDate, 'month')
                  .format('YYYY-MM-DDTHH:mm:ss')
              : null,
            filterBySite !== 0 ? filterBySite : null
          )
          .then((res) => {
            setStatsData(res.data);
            setIsLoading(false);
          });
      } else {
        performanceApi
          .getProviderPerformance(
            filterByProvider ? filterByProvider : providerId,
            filterByDate !== 0
              ? dayjs()
                  .subtract(filterByDate, 'month')
                  .format('YYYY-MM-DDTHH:mm:ss')
              : null,
            filterBySite !== 0 ? filterBySite : null
          )
          .then((res) => {
            setStatsData(res.data);
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
    }
  }, [filterByDate, filterBySite, filterByProvider]);

  // Available sites list for sites filter dropdown
  useEffect(() => {
    if (providerId != null || filterByProvider != null) {
      sitesApi
        .getProviderSites(filterByProvider ? filterByProvider : providerId)
        .then((res) => {
          const sitesList = [];
          sitesList.push({ value: 0, display: 'All sites' });

          const tempSites = [];
          res.data.map((value) => {
            tempSites.push({ value: value.id, display: value.name });
          });

          tempSites.sort((a, b) => (a.name > b.name ? -1 : 1));

          const providerSites = sitesList.concat(tempSites);

          setProviderSites(providerSites);
        });
    } else {
      const sitesList = [];
      sitesList.push({ value: 0, display: 'All sites' });
      setProviderSites(sitesList);
    }
  }, [filterByProvider]);

  //Handles user name in provider page title
  useEffect(() => {
    if (user != null) {
      userApi.getUserName(user.nameid).then((res) => {
        const username = `${res.data.lastName}, ${res.data.firstName}`;
        setUserName(username);

        if (providerId == null && user.role == 'LLIFAdmin') {
          setUserNameTableHeader('');
        } else if (providerId != null && user.role == 'LLIFAdmin') {
          setUserNameTableHeader(username);
        } else if (user.role == 'Physician') {
          setUserNameTableHeader(username);
        }
      });
    }
  }, []);

  // Providers list for provider filter dropdown
  useEffect(() => {
    if (user.role == 'Admin' || user.role == 'LLIFAdmin') {
      userApi.getProviders().then((res) => {
        const providers = [];
        const sortedArr = [...res.data].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        sortedArr.map((value) => {
          providers.push({ value: value.id, display: value.name });
        });
        // res.data.map((value) => {
        //   providers.push({ value: value.id, display: value.name });
        // });
        setProviderList(providers);
      });
    }
  }, []);

  // Handles user name table when provider filter is changed
  useEffect(() => {
    providerList.map(({ value, display }) => {
      if (filterByProvider == value) {
        setUserNameTableHeader(display);
      }
    });
  }, [providerList, filterByProvider]);

  //Table column headers
  const tableHeaders = [
    '', //No name for the first column
    userNameTableHeader,
    'Peer Average',
    'Peer Distribution',
  ];

  const statsKeys = [
    {
      name: 'numberOfShifts',
      header: 'Number of Shifts',
      asInteger: true,
    },
    {
      name: 'averageShiftLengthHours',
      header: 'Average Shift Length',
    },
    {
      name: 'visitsSeenPerHour',
      header: 'Visits Seen Per Hour',
    },
    {
      name: 'totalVisits',
      header: 'Total number of patient visits included',
      asInteger: true,
    },
    {
      name: 'revisitRate',
      header: 'Revisit Rate',
      asProportion: true,
    },
    {
      name: 'readmissionRate',
      header: 'Readmission Rate',
      asProportion: true,
    },
    {
      name: 'meanPiaToConsultTimeMinutes',
      header: 'Mean PIA to Consult time',
      asMinutes: true,
    },
    {
      name: 'meanPiaToDischargeTimeMinutes',
      header: 'Mean PIA to Discharge time',
      asMinutes: true,
    },
    {
      name: 'proportionOfConsultsAdmitted',
      header: 'Proportion of Consults Admitted',
      asProportion: true,
    },
    {
      name: 'proportionOfPatientsReceivingCTScan',
      header: 'Proportion of Patients Receiving CT Scan',
      asProportion: true,
    },
    {
      name: 'proportionOfPatientsReceivingUltrasound',
      header: 'Proportion of Patients Receiving Ultrasound',
      asProportion: true,
    },
  ];

  // Handles each row with the specific stat results
  const rows = statsKeys.map(
    ({ name, header, asInteger, asProportion, asMinutes }, index) => {
      const stat = statsData.find((stat) => stat.name == name);
      const precision = asInteger ? 0 : 2;
      const suffix = asProportion ? ' %' : '';

      let makeStat;

      if (asMinutes) {
        makeStat = (stat, value) => {
          if (!stat) return '';

          const totalMinutes = value.toFixed(0);

          const hours = Math.floor(totalMinutes / 60);
          const minutes = totalMinutes - 60 * hours;

          return `${hours} hours and ${minutes} minutes`;
        };
      } else {
        makeStat = (stat, value) =>
          stat ? value.toFixed(precision) + suffix : '';
      }

      const userAverage = makeStat(stat, stat?.providerValue);
      const peerAverage = makeStat(stat, stat?.departmentAverage);

      // peerAverageGraph and userAverageGraph is specifically for peerDistribution graph where
      // the string variable suffix is not added for the calculations of the graph
      const peerAverageGraph = stat
        ? stat.departmentAverage.toFixed(precision)
        : '';

      const userAverageGraph = stat
        ? stat.providerValue.toFixed(precision)
        : '';

      let peerDistribution = stat ? stat.allProviderValues : [];

      if (peerDistribution.length > 0) {
        const maxValue = Math.max(...peerDistribution);
        const minValue = Math.min(...peerDistribution);

        if (maxValue == minValue) {
          peerDistribution = [];
        } else {
          peerDistribution = peerDistribution
            .filter((value) => value != userAverageGraph)
            .map((value) => (value - minValue) / (maxValue - minValue))
            .filter((value) => value != null)
            .map((value) => ({
              point: value,
              isUser: false,
              isPeerAverage: false,
            }));

          peerDistribution.push({
            point: (peerAverageGraph - minValue) / (maxValue - minValue),
            isUser: false,
            isPeerAverage: true,
          });

          peerDistribution.push({
            point: (userAverageGraph - minValue) / (maxValue - minValue),
            isUser: true,
            isPeerAverage: false,
          });
        }
      }

      return {
        id: index + 1,
        rowHeading: header,
        userAverage,
        peerAverage,
        peerDistribution,
      };
    }
  );

  return (
    <GridLayout>
      {isLoading ? (
        <Paper sx={{ padding: 8, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={100} />
        </Paper>
      ) : (
        <Paper sx={{ padding: 3 }}>
          <>
            <Typography variant='h4' sx={{ paddingBottom: 1 }}>
              Provider Practice - {userName}
            </Typography>

            {/* filters (Date, site, and physicians)*/}
            <Grid container>
              <Box
                sx={{
                  flexGrow: 1,
                  borderRadius: 1,
                  marginTop: 2,
                  paddingBottom: 1,
                  backgroundColor: '#FFFFFF',
                }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent='flex-start'
                    spacing={2}
                    sx={{
                      marginY: 1,
                      marginX: 1,
                    }}
                  >
                    <StyledBasicDropdown
                      dropdownListItems={dateRanges}
                      id='filterbydate'
                      label='Filter by Date'
                      item={filterByDate}
                      setItem={setFilterByDate}
                    />

                    {(user.role == 'Admin' || user.role == 'LLIFAdmin') && (
                      <StyledBasicDropdown
                        dropdownListItems={providerList}
                        id='filterbyprovider'
                        label='Filter by Physician'
                        item={filterByProvider}
                        setItem={setFilterByProvider}
                      />
                    )}

                    <StyledBasicDropdown
                      dropdownListItems={providerSites}
                      id='filterbysite'
                      label='Filter by Site'
                      item={filterBySite}
                      setItem={setFilterBySite}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Provider table */}
            <ProviderTable data={rows} tableHeaders={tableHeaders} />
          </>
        </Paper>
      )}
    </GridLayout>
  );
};

export default ProviderPerformance;
