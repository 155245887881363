export const sidebarStyles = {
    drawer: {
      width: 280,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        width: 280,
        boxSizing: "border-box",
      },
      "& .Mui-selected": {
        color: "#0a2d48",
      },
    },
    icons: {
      color: "#6d7073",
      marginLeft: "20px",
    },
    text: {
      "& span": {
        marginLeft: "-10px",
        fontWeight: "600",
        fontSize: "14px",
      },
    },
    subText: {
      "& span": {
        marginLeft: "-10px",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
  
    item: {
      color: "rgba(255, 255, 255, 0.7)",
      "&:hover, &:focus": {
        bgcolor: "rgba(255, 255, 255, 0.08)",
      },
      boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
      py: 1.5,
      px: 3,
    },
    toolbar: {
      display: "flex",
      alignItems: "right",
      marginLeft: "20px",
    },
  };
  