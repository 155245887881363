import React from "react";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";

// we cannot use the context in the same component where it is being provided such as App.js.
// this is a wrapper for the rest of the children of the Provider
const AuthWrapper = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default AuthWrapper;
