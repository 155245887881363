import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Typography,
  Container,
  Grid,
  Alert,
  CircularProgress,
  Avatar,
  Modal,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Layout from '../components/common/Layout/Layout';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LockOutlined } from '@mui/icons-material';
import AuthContext from '../context/AuthContext';
import LicenseAgreementDialog from '../components/dialogBox/UserLicenseAgreementDialog';
import { useUserApi } from '../api/User';
import apiEndpoints from '../utils/ApiEndpoints';
import axios from 'axios';

const theme = createTheme();

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [userEmailAndPassword, setUserEmailAndPassword] = useState({
    email: '',
    password: '',
  });
  const [userLicenseUpdated, setUserLicenseUpdated] = useState({
    error: null,
    isUserLicenseAccepted: false,
    userLicenseId: 0,
  });

  const userApi = useUserApi();

  const [licenseDialog, setLicenseDialog] = useState({
    generalTitle: '',
    titleSection1: '',
    section1: '',
    titleSection2: '',
    section2: '',
    titleSection3: '',
    section3: '',
    titleSection4: '',
    section4: '',
    titleSection5: '',
    section5: '',
    titleSection6: '',
    section6: '',
    titleSection7: '',
    section7: '',
    titleSection8: '',
    section8: '',
    titleSection9: '',
    section9: '',
    titleSection10: '',
    section10: '',
    titleSection11: '',
    section11: '',
    titleSection12: '',
    section12: '',
    isOpen: false,
  });

  const { signInUser, error, setError } = useContext(AuthContext);
  const navigate = useNavigate();

  const updateEULAAcceptedDate = async (loginDTO) => {
    axios.put(apiEndpoints.EULA_acceptedDate, loginDTO);
  };

  //Handles action to close modal dialog when user declines EULA
  const closeModalEULA = (isClose) => {
    setOpenModal(isClose);
    setIsLoading(false);
    formik.setValues({
      email: '',
      password: '',
    });
  };

  //Handles user info regarding password updated on first login
  useEffect(() => {
    if (userEmailAndPassword.email !== '') {
      userApi.getUserInfoEULA(userEmailAndPassword.email).then((res) => {
        setUserLicenseUpdated(res.data);
      });
    }
  }, [userEmailAndPassword]);

  //Gets end user license agreement by UserLicenseId
  useEffect(() => {
    if (userLicenseUpdated.userLicenseId !== 0) {
      userApi
        .getEndUserLicenseAgreement(userLicenseUpdated.userLicenseId)
        .then((res) => {
          const licenseContent = res.data.description.split('//');

          if (!userLicenseUpdated.isUserLicenseAccepted) {
            setLicenseDialog({
              generalTitle: licenseContent[0],
              summary: licenseContent[1],
              titleSection1: licenseContent[2],
              section1: licenseContent[3],
              titleSection2: licenseContent[4],
              section2: licenseContent[5],
              titleSection3: licenseContent[6],
              section3: licenseContent[7],
              titleSection4: licenseContent[8],
              section4: licenseContent[9],
              titleSection5: licenseContent[10],
              section5: licenseContent[11],
              titleSection6: licenseContent[12],
              section6: licenseContent[13],
              titleSection7: licenseContent[14],
              section7a: licenseContent[15],
              section7b: licenseContent[16],
              section7c: licenseContent[17],
              titleSection8: licenseContent[18],
              section8: licenseContent[19],
              titleSection9: licenseContent[20],
              section9: licenseContent[21],
              titleSection10: licenseContent[22],
              section10: licenseContent[23],
              titleSection11: licenseContent[24],
              section11: licenseContent[25],
              titleSection12: licenseContent[26],
              section12: licenseContent[27],
              isOpen: true,

              onConfirm: async () => {
                setLicenseDialog({
                  ...licenseDialog,
                  isOpen: false,
                });
                // set date time when user accepted the EULA
                updateEULAAcceptedDate(userEmailAndPassword);

                // Sign in user after EULA has been accepted
                signInUser(userEmailAndPassword);
              },

              onDecline: async () => {
                setLicenseDialog({
                  ...licenseDialog,
                  isOpen: false,
                });

                setOpenModal(true);
              },
            });
          } else {
            signInUser(userEmailAndPassword);
          }
        });
    }
  }, [userLicenseUpdated]);

  // Handles processing form data for login page
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email format').required('Required'),
      password: Yup.string().required('Required'),
    }),
    validateOnBlur: false,
    onSubmit: (values) => {
      setIsLoading(true);
      setUserEmailAndPassword(values);
    },
  });

  useEffect(() => {
    setError(null);
  }, []);

  return (
    <Layout>
      <Typography variant='h6' sx={{ textAlign: 'center', paddingTop: 3 }}>
        Physician Practice Dashboard
      </Typography>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Paper
          variant='outlined'
          sx={{
            elevation: 12,
            padding: theme.spacing(3),
            marginTop: theme.spacing(6),
          }}
        >
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#042c49' }}>
              <LockOutlined />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <Box
              component='form'
              onSubmit={formik.handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin='normal'
                fullWidth
                id='email'
                label='Email'
                name='email'
                autoComplete='email'
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null
                }
              />

              <TextField
                margin='normal'
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : null
                }
              />

              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                {...(isLoading &&
                  !error && {
                    endIcon: <CircularProgress size={25} />,
                    disabled: true,
                  })}
              >
                Sign In
              </Button>
              {error && (
                <Grid container justifyContent='flex-end'>
                  <Grid item xs>
                    <Box display='flex' justifyContent='center'>
                      {/* When user tries to login, if the 30 day period from the last update of password have passed,
                      force user to update password, otherwise let user login to the dashboard */}
                      {error === 'Password needs update' ? (
                        <Alert
                          variant='outlined'
                          severity='error'
                          action={
                            <Button
                              color='inherit'
                              size='small'
                              onClick={() => {
                                navigate('/autoresetpassword');
                              }}
                            >
                              Update
                            </Button>
                          }
                        >
                          {error}
                        </Alert>
                      ) : (
                        <Alert variant='outlined' severity='error'>
                          {error}
                        </Alert>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid container justifyContent='flex-end'>
                <Grid item xs>
                  <Box display='flex' justifyContent='center'>
                    <Button
                      fullWidth
                      variant='text'
                      sx={{ mt: 1, mb: 2 }}
                      onClick={() => {
                        navigate('/forgotpassword');
                      }}
                    >
                      Forgot password?
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
        {/* EULA dialog box to accept or decline license */}
        <LicenseAgreementDialog
          licenseDialog={licenseDialog}
          setLicenseDialog={setLicenseDialog}
        />
        {/* Modal box when user declines EULA */}
        <Modal open={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              textAlign: 'center',
            }}
          >
            <WarningAmberIcon color='warning' />
            <Typography id='modal-modal-title' variant='h6' component='h6'>
              Warning
            </Typography>
            <Typography id='modal-modal-description' sx={{ mt: 2 }}>
              By declining End of User License Agreement, you won't be able to
              login to the dashboard.
            </Typography>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='flex-end'
              sx={{ marginTop: 1 }}
            >
              <Button variant='text' onClick={() => closeModalEULA(false)}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </Container>
    </Layout>
  );
};

export default Login;
