import { useState, useContext, useRef } from "react";
import AuthContext from "../../context/AuthContext";
import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import { useIdleTimer } from "react-idle-timer";

const AutoLoggedOut = () => {
  const { user, signOutUser, pauseTimer } = useContext(AuthContext);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const timer = useRef(null);

  // Handles session logout
  const sessionLogOut = () => {
    setIsModelOpen(false);
    clearTimeout(timer.current);
    timer.current = null;
    signOutUser();
  };

  //Handles action to keep session active
  const stayActive = () => {
    setIsModelOpen(false);
    clearTimeout(timer.current);
    timer.current = null;
  };

  //when the user is inactive
  const handleOnIdle = (event) => {
    //keep session active if user is login and pauseTimer is true
    if(user && pauseTimer) {
      stayActive();
    }
    else if (user && !pauseTimer) {
      setIsModelOpen(true);
      timer.current = setTimeout(sessionLogOut, 5000);
    }
  };

  // Checks whether user has been inactive after 5 minutes period.
  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <Modal
      open={isModelOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          outline: "none",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h6">
          Session Expire Warning
        </Typography>
        <Divider sx={{ paddingY: 1 }} />
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Your session is about to expire. You will be logged out in 5 seconds.
        </Typography>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button variant="text" onClick={() => sessionLogOut()}>
            Log me Out
          </Button>
          <Button variant="text" onClick={() => stayActive(false)}>
            Keep me signed in
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AutoLoggedOut;
