import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoutes = ({ allowedRoles }) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  return (Array.isArray(user?.role) &&
    user.role?.find((role) => allowedRoles?.includes(role))) ||
    allowedRoles.includes(user?.role) ? ( //user.role can be array or property
    <Outlet />
  ) : user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  ); //state - replace for to keep track location history
};

export default PrivateRoutes;
