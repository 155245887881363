import useAxios from "../hooks/useAxios";
import apiEndpoints from "../utils/ApiEndpoints";
export const useSitesApi = () => {
  const api = useAxios();

  return {
    // endpoint used to get all the sites a user belongs to
    getProviderSites: (providerId) =>
      api.get(
        apiEndpoints.sites_byProvider.replace("{providerId}", providerId),
        {
          params: {
            providerId,
          },
        }
      ),
  };
};
