import React from "react";
import {
  FormControl,
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from "@mui/material";

const StyledBasicDropdown = (props) => {
  const {
    dropdownListItems,
    autoComplete,
    name,
    id,
    label,
    autoFocus,
    value,
    disabled,
    InputProps,
    errorMessage,
    item,
    setItem,
    defaultValue,
    minWidth = 250,
  } = props;

  const handleChange = (event) => {
    setItem(event.target.value);
  };

  return (
    <Box>
      <FormControl
        sx={{ my: 1, marginRight: 5, minWidth: { minWidth }, backgroundColor: "white", }}
        size="small"
      >
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={item}
          label={label}
          name={name}
          onChange={handleChange}
          defaultValue={defaultValue}
          MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}

        >
          {dropdownListItems.map(({ display, value }, index) => (
            <MenuItem key={index} value={value}>
              {display}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText>{errorMessage}</FormHelperText>
    </Box>
  );
};

export default StyledBasicDropdown;
