import useAxios from "../hooks/useAxios";
import apiEndpoints from "../utils/ApiEndpoints";
import axios from "axios";

export const useUserApi = () => {
  const api = useAxios();

  return {
    // endpoint to get user information (first name and last name)
    getUserName: (userId) =>
      api.get(apiEndpoints.userName_byUserId.replace("{userId}", userId), {
        params: {
          userId,
        },
      }),
    // endpoint to get a list of all the providers
    getProviders: () =>
      api.get(
        apiEndpoints.provider_list,
      ),
    // endpoint to get corresponding end user license agreement
    getEndUserLicenseAgreement: (userLicenseId) =>// change to normal axios
      axios.get(apiEndpoints.endUser_licenseAgreement.replace("{userLicenseId}", userLicenseId)),

    // endpoint to get corresponding user information regarding password update on first login
    getUserInfoEULA: (userEmail) => // change to normal axios
      axios.get(apiEndpoints.userInfo_byEmail.replace("{userEmail}", userEmail)),
  };
};
