import "./App.css";
import { useContext } from "react";
import RouteConfig from "./routes/RouteConfig";
import SideBarWithDrawer from "./components/sideBar/sideBarWithDrawer";
import UnauthenticatedLayout from "./components/common/unauthenticatedLayout/UnauthenticatedLayout";
import AuthContext from "./context/AuthContext";
import AutoLoggedOut from "./components/autoLogout/AutoLoggedOut";

function App() {
  const { user } = useContext(AuthContext);
  return (
    <>
      {user ? <SideBarWithDrawer content={<RouteConfig />} /> : ""}
      {!user ? <UnauthenticatedLayout content={<RouteConfig />} /> : ""}
      <AutoLoggedOut />
    </>
  )
}

export default App;
