import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    Button,
    Backdrop,
  } from "@mui/material";
  import { useTheme } from "@mui/material/styles";
  import { Check, Close } from "@mui/icons-material";
  
  import React from "react";
  
  const ConfirmationDialog = (props) => {
    const { confirmDialog, setConfirmDialog } = props;
    const theme = useTheme();
    return (
      <Dialog
        open={confirmDialog.isOpen}
        maxWidth="sm"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          position: "absolute",
          top: theme.spacing(5),
          paddingY: theme.spacing(2),
        }}
      >
        <DialogTitle sx={{ paddingY: theme.spacing(3) }}>
          <Typography component="span" variant="h6">
            {confirmDialog.title}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{ paddingY: theme.spacing(5), paddingRight: theme.spacing(3) }}
        >
          <Typography variant="subtitle2"> {confirmDialog.subTitle}</Typography>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: theme.spacing(2) }}>
          <Button
            variant="contained"
            color="success"
            onClick={confirmDialog.onConfirm}
            startIcon={<Check />}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
            startIcon={<Close />}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default ConfirmationDialog;
  